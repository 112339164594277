@import url('https://themes.googleusercontent.com/fonts/css?kit=DXI1ORHCpsQm3Vp6mXoaTYjHOPuHsY4KgPraGYKqiQW0oqs0LpE6AVtICQrhjlj4_fmMCNj7fWVIpZlv0Vrvsw');
ol.lst-kix_63mk0cxee5lz-7.start {
	counter-reset: lst-ctn-kix_63mk0cxee5lz-7 0;
}
.lst-kix_list_2-1 > li {
	counter-increment: lst-ctn-kix_list_2-1;
}
ol.lst-kix_b5xso8t77fn6-1 {
	list-style-type: none;
}
ol.lst-kix_b5xso8t77fn6-0 {
	list-style-type: none;
}
.lst-kix_b5xso8t77fn6-0 > li {
	counter-increment: lst-ctn-kix_b5xso8t77fn6-0;
}
ol.lst-kix_b5xso8t77fn6-3 {
	list-style-type: none;
}
ol.lst-kix_b5xso8t77fn6-2 {
	list-style-type: none;
}
ol.lst-kix_b5xso8t77fn6-5 {
	list-style-type: none;
}
ol.lst-kix_b5xso8t77fn6-4 {
	list-style-type: none;
}
ol.lst-kix_b5xso8t77fn6-7 {
	list-style-type: none;
}
ol.lst-kix_b5xso8t77fn6-6 {
	list-style-type: none;
}
ol.lst-kix_b57g1j47pg3v-5.start {
	counter-reset: lst-ctn-kix_b57g1j47pg3v-5 0;
}
ol.lst-kix_b5xso8t77fn6-8 {
	list-style-type: none;
}
ol.lst-kix_b5xso8t77fn6-3.start {
	counter-reset: lst-ctn-kix_b5xso8t77fn6-3 0;
}
.lst-kix_s4zdcr4jvz42-4 > li {
	counter-increment: lst-ctn-kix_s4zdcr4jvz42-4;
}
ol.lst-kix_list_2-3.start {
	counter-reset: lst-ctn-kix_list_2-3 0;
}
ol.lst-kix_q01hydxackv6-1.start {
	counter-reset: lst-ctn-kix_q01hydxackv6-1 0;
}
ol.lst-kix_hh5nrmz5lj4w-1 {
	list-style-type: none;
}
ol.lst-kix_hh5nrmz5lj4w-2 {
	list-style-type: none;
}
ol.lst-kix_hh5nrmz5lj4w-0 {
	list-style-type: none;
}
ol.lst-kix_hh5nrmz5lj4w-5 {
	list-style-type: none;
}
ol.lst-kix_hh5nrmz5lj4w-6 {
	list-style-type: none;
}
ol.lst-kix_hh5nrmz5lj4w-3 {
	list-style-type: none;
}
ol.lst-kix_hh5nrmz5lj4w-4 {
	list-style-type: none;
}
.lst-kix_ukbavo85rgyb-5 > li {
	counter-increment: lst-ctn-kix_ukbavo85rgyb-5;
}
ol.lst-kix_hh5nrmz5lj4w-7 {
	list-style-type: none;
}
ol.lst-kix_hh5nrmz5lj4w-8 {
	list-style-type: none;
}
.lst-kix_ccpf54yj8ft4-4 > li {
	counter-increment: lst-ctn-kix_ccpf54yj8ft4-4;
}
.lst-kix_63mk0cxee5lz-4 > li {
	counter-increment: lst-ctn-kix_63mk0cxee5lz-4;
}
.lst-kix_list_1-4 > li {
	counter-increment: lst-ctn-kix_list_1-4;
}
.lst-kix_hh5nrmz5lj4w-1 > li {
	counter-increment: lst-ctn-kix_hh5nrmz5lj4w-1;
}
ol.lst-kix_list_1-6.start {
	counter-reset: lst-ctn-kix_list_1-6 0;
}
.lst-kix_ru1bpjfhdry7-0 > li {
	counter-increment: lst-ctn-kix_ru1bpjfhdry7-0;
}
.lst-kix_s4zdcr4jvz42-8 > li {
	counter-increment: lst-ctn-kix_s4zdcr4jvz42-8;
}
ol.lst-kix_ru1bpjfhdry7-4.start {
	counter-reset: lst-ctn-kix_ru1bpjfhdry7-4 0;
}
ol.lst-kix_q01hydxackv6-6.start {
	counter-reset: lst-ctn-kix_q01hydxackv6-6 0;
}
ol.lst-kix_b57g1j47pg3v-0.start {
	counter-reset: lst-ctn-kix_b57g1j47pg3v-0 0;
}
.lst-kix_ukbavo85rgyb-6 > li:before {
	content: '' counter(lst-ctn-kix_ukbavo85rgyb-0, decimal) '.'
		counter(lst-ctn-kix_ukbavo85rgyb-1, decimal) '.'
		counter(lst-ctn-kix_ukbavo85rgyb-2, decimal) '.'
		counter(lst-ctn-kix_ukbavo85rgyb-3, decimal) '.'
		counter(lst-ctn-kix_ukbavo85rgyb-4, decimal) '.'
		counter(lst-ctn-kix_ukbavo85rgyb-5, decimal) '.'
		counter(lst-ctn-kix_ukbavo85rgyb-6, decimal) '. ';
}
ol.lst-kix_s4zdcr4jvz42-3.start {
	counter-reset: lst-ctn-kix_s4zdcr4jvz42-3 0;
}
.lst-kix_ukbavo85rgyb-5 > li:before {
	content: '' counter(lst-ctn-kix_ukbavo85rgyb-0, decimal) '.'
		counter(lst-ctn-kix_ukbavo85rgyb-1, decimal) '.'
		counter(lst-ctn-kix_ukbavo85rgyb-2, decimal) '.'
		counter(lst-ctn-kix_ukbavo85rgyb-3, decimal) '.'
		counter(lst-ctn-kix_ukbavo85rgyb-4, decimal) '.'
		counter(lst-ctn-kix_ukbavo85rgyb-5, decimal) '. ';
}
.lst-kix_ukbavo85rgyb-3 > li:before {
	content: '' counter(lst-ctn-kix_ukbavo85rgyb-0, decimal) '.'
		counter(lst-ctn-kix_ukbavo85rgyb-1, decimal) '.'
		counter(lst-ctn-kix_ukbavo85rgyb-2, decimal) '.'
		counter(lst-ctn-kix_ukbavo85rgyb-3, decimal) '. ';
}
.lst-kix_ukbavo85rgyb-4 > li:before {
	content: '' counter(lst-ctn-kix_ukbavo85rgyb-0, decimal) '.'
		counter(lst-ctn-kix_ukbavo85rgyb-1, decimal) '.'
		counter(lst-ctn-kix_ukbavo85rgyb-2, decimal) '.'
		counter(lst-ctn-kix_ukbavo85rgyb-3, decimal) '.'
		counter(lst-ctn-kix_ukbavo85rgyb-4, decimal) '. ';
}
.lst-kix_ukbavo85rgyb-1 > li:before {
	content: '' counter(lst-ctn-kix_ukbavo85rgyb-0, decimal) '.'
		counter(lst-ctn-kix_ukbavo85rgyb-1, decimal) '. ';
}
.lst-kix_list_2-5 > li {
	counter-increment: lst-ctn-kix_list_2-5;
}
.lst-kix_list_2-8 > li {
	counter-increment: lst-ctn-kix_list_2-8;
}
.lst-kix_ukbavo85rgyb-1 > li {
	counter-increment: lst-ctn-kix_ukbavo85rgyb-1;
}
.lst-kix_ukbavo85rgyb-2 > li:before {
	content: '' counter(lst-ctn-kix_ukbavo85rgyb-0, decimal) '.'
		counter(lst-ctn-kix_ukbavo85rgyb-1, decimal) '.'
		counter(lst-ctn-kix_ukbavo85rgyb-2, decimal) '. ';
}
.lst-kix_ukbavo85rgyb-0 > li:before {
	content: '' counter(lst-ctn-kix_ukbavo85rgyb-0, decimal) '. ';
}
.lst-kix_ccpf54yj8ft4-1 > li {
	counter-increment: lst-ctn-kix_ccpf54yj8ft4-1;
}
.lst-kix_hh5nrmz5lj4w-4 > li {
	counter-increment: lst-ctn-kix_hh5nrmz5lj4w-4;
}
ol.lst-kix_ukbavo85rgyb-0.start {
	counter-reset: lst-ctn-kix_ukbavo85rgyb-0 0;
}
.lst-kix_63mk0cxee5lz-7 > li {
	counter-increment: lst-ctn-kix_63mk0cxee5lz-7;
}
.lst-kix_ru1bpjfhdry7-3 > li {
	counter-increment: lst-ctn-kix_ru1bpjfhdry7-3;
}
ol.lst-kix_ccpf54yj8ft4-6.start {
	counter-reset: lst-ctn-kix_ccpf54yj8ft4-6 0;
}
.lst-kix_ukbavo85rgyb-7 > li:before {
	content: '' counter(lst-ctn-kix_ukbavo85rgyb-0, decimal) '.'
		counter(lst-ctn-kix_ukbavo85rgyb-1, decimal) '.'
		counter(lst-ctn-kix_ukbavo85rgyb-2, decimal) '.'
		counter(lst-ctn-kix_ukbavo85rgyb-3, decimal) '.'
		counter(lst-ctn-kix_ukbavo85rgyb-4, decimal) '.'
		counter(lst-ctn-kix_ukbavo85rgyb-5, decimal) '.'
		counter(lst-ctn-kix_ukbavo85rgyb-6, decimal) '.'
		counter(lst-ctn-kix_ukbavo85rgyb-7, decimal) '. ';
}
ol.lst-kix_list_2-5.start {
	counter-reset: lst-ctn-kix_list_2-5 0;
}
.lst-kix_b57g1j47pg3v-2 > li {
	counter-increment: lst-ctn-kix_b57g1j47pg3v-2;
}
ol.lst-kix_s4zdcr4jvz42-8.start {
	counter-reset: lst-ctn-kix_s4zdcr4jvz42-8 0;
}
ol.lst-kix_b57g1j47pg3v-7.start {
	counter-reset: lst-ctn-kix_b57g1j47pg3v-7 0;
}
ol.lst-kix_ru1bpjfhdry7-6.start {
	counter-reset: lst-ctn-kix_ru1bpjfhdry7-6 0;
}
ol.lst-kix_ru1bpjfhdry7-6 {
	list-style-type: none;
}
ol.lst-kix_ru1bpjfhdry7-5 {
	list-style-type: none;
}
ol.lst-kix_ru1bpjfhdry7-4 {
	list-style-type: none;
}
ol.lst-kix_ru1bpjfhdry7-3 {
	list-style-type: none;
}
.lst-kix_b5xso8t77fn6-7 > li {
	counter-increment: lst-ctn-kix_b5xso8t77fn6-7;
}
ol.lst-kix_s4zdcr4jvz42-5.start {
	counter-reset: lst-ctn-kix_s4zdcr4jvz42-5 0;
}
.lst-kix_list_1-8 > li {
	counter-increment: lst-ctn-kix_list_1-8;
}
ol.lst-kix_ru1bpjfhdry7-8 {
	list-style-type: none;
}
.lst-kix_q01hydxackv6-2 > li {
	counter-increment: lst-ctn-kix_q01hydxackv6-2;
}
ol.lst-kix_ru1bpjfhdry7-7 {
	list-style-type: none;
}
ol.lst-kix_list_1-4.start {
	counter-reset: lst-ctn-kix_list_1-4 0;
}
ol.lst-kix_hh5nrmz5lj4w-3.start {
	counter-reset: lst-ctn-kix_hh5nrmz5lj4w-3 0;
}
ol.lst-kix_list_1-1.start {
	counter-reset: lst-ctn-kix_list_1-1 0;
}
ol.lst-kix_ru1bpjfhdry7-2 {
	list-style-type: none;
}
ol.lst-kix_ru1bpjfhdry7-1 {
	list-style-type: none;
}
ol.lst-kix_ru1bpjfhdry7-0 {
	list-style-type: none;
}
ol.lst-kix_ccpf54yj8ft4-4.start {
	counter-reset: lst-ctn-kix_ccpf54yj8ft4-4 0;
}
.lst-kix_ru1bpjfhdry7-2 > li:before {
	content: '' counter(lst-ctn-kix_ru1bpjfhdry7-0, decimal) '.'
		counter(lst-ctn-kix_ru1bpjfhdry7-1, decimal) '.'
		counter(lst-ctn-kix_ru1bpjfhdry7-2, decimal) '. ';
}
.lst-kix_ru1bpjfhdry7-6 > li:before {
	content: '' counter(lst-ctn-kix_ru1bpjfhdry7-0, decimal) '.'
		counter(lst-ctn-kix_ru1bpjfhdry7-1, decimal) '.'
		counter(lst-ctn-kix_ru1bpjfhdry7-2, decimal) '.'
		counter(lst-ctn-kix_ru1bpjfhdry7-3, decimal) '.'
		counter(lst-ctn-kix_ru1bpjfhdry7-4, decimal) '.'
		counter(lst-ctn-kix_ru1bpjfhdry7-5, decimal) '.'
		counter(lst-ctn-kix_ru1bpjfhdry7-6, decimal) '. ';
}
.lst-kix_ru1bpjfhdry7-0 > li:before {
	content: '' counter(lst-ctn-kix_ru1bpjfhdry7-0, decimal) '. ';
}
.lst-kix_ru1bpjfhdry7-8 > li:before {
	content: '' counter(lst-ctn-kix_ru1bpjfhdry7-0, decimal) '.'
		counter(lst-ctn-kix_ru1bpjfhdry7-1, decimal) '.'
		counter(lst-ctn-kix_ru1bpjfhdry7-2, decimal) '.'
		counter(lst-ctn-kix_ru1bpjfhdry7-3, decimal) '.'
		counter(lst-ctn-kix_ru1bpjfhdry7-4, decimal) '.'
		counter(lst-ctn-kix_ru1bpjfhdry7-5, decimal) '.'
		counter(lst-ctn-kix_ru1bpjfhdry7-6, decimal) '.'
		counter(lst-ctn-kix_ru1bpjfhdry7-7, decimal) '.'
		counter(lst-ctn-kix_ru1bpjfhdry7-8, decimal) '. ';
}
ol.lst-kix_hh5nrmz5lj4w-1.start {
	counter-reset: lst-ctn-kix_hh5nrmz5lj4w-1 0;
}
ol.lst-kix_list_2-8.start {
	counter-reset: lst-ctn-kix_list_2-8 0;
}
.lst-kix_b57g1j47pg3v-1 > li {
	counter-increment: lst-ctn-kix_b57g1j47pg3v-1;
}
ol.lst-kix_b5xso8t77fn6-0.start {
	counter-reset: lst-ctn-kix_b5xso8t77fn6-0 0;
}
.lst-kix_ru1bpjfhdry7-4 > li:before {
	content: '' counter(lst-ctn-kix_ru1bpjfhdry7-0, decimal) '.'
		counter(lst-ctn-kix_ru1bpjfhdry7-1, decimal) '.'
		counter(lst-ctn-kix_ru1bpjfhdry7-2, decimal) '.'
		counter(lst-ctn-kix_ru1bpjfhdry7-3, decimal) '.'
		counter(lst-ctn-kix_ru1bpjfhdry7-4, decimal) '. ';
}
ol.lst-kix_q01hydxackv6-8.start {
	counter-reset: lst-ctn-kix_q01hydxackv6-8 0;
}
.lst-kix_q01hydxackv6-3 > li {
	counter-increment: lst-ctn-kix_q01hydxackv6-3;
}
.lst-kix_hh5nrmz5lj4w-5 > li {
	counter-increment: lst-ctn-kix_hh5nrmz5lj4w-5;
}
.lst-kix_63mk0cxee5lz-0 > li {
	counter-increment: lst-ctn-kix_63mk0cxee5lz-0;
}
.lst-kix_s4zdcr4jvz42-0 > li {
	counter-increment: lst-ctn-kix_s4zdcr4jvz42-0;
}
ol.lst-kix_q01hydxackv6-1 {
	list-style-type: none;
}
ol.lst-kix_q01hydxackv6-2 {
	list-style-type: none;
}
ol.lst-kix_q01hydxackv6-3 {
	list-style-type: none;
}
.lst-kix_ccpf54yj8ft4-8 > li {
	counter-increment: lst-ctn-kix_ccpf54yj8ft4-8;
}
.lst-kix_b5xso8t77fn6-8 > li {
	counter-increment: lst-ctn-kix_b5xso8t77fn6-8;
}
ol.lst-kix_q01hydxackv6-4 {
	list-style-type: none;
}
.lst-kix_list_1-3 > li {
	counter-increment: lst-ctn-kix_list_1-3;
}
ol.lst-kix_q01hydxackv6-0 {
	list-style-type: none;
}
ol.lst-kix_q01hydxackv6-5 {
	list-style-type: none;
}
ol.lst-kix_b5xso8t77fn6-1.start {
	counter-reset: lst-ctn-kix_b5xso8t77fn6-1 0;
}
ol.lst-kix_q01hydxackv6-6 {
	list-style-type: none;
}
ol.lst-kix_q01hydxackv6-7 {
	list-style-type: none;
}
ol.lst-kix_q01hydxackv6-8 {
	list-style-type: none;
}
.lst-kix_ru1bpjfhdry7-4 > li {
	counter-increment: lst-ctn-kix_ru1bpjfhdry7-4;
}
.lst-kix_ru1bpjfhdry7-8 > li {
	counter-increment: lst-ctn-kix_ru1bpjfhdry7-8;
}
.lst-kix_b57g1j47pg3v-3 > li:before {
	content: '' counter(lst-ctn-kix_b57g1j47pg3v-0, decimal) '.'
		counter(lst-ctn-kix_b57g1j47pg3v-1, decimal) '.'
		counter(lst-ctn-kix_b57g1j47pg3v-2, decimal) '.'
		counter(lst-ctn-kix_b57g1j47pg3v-3, decimal) '. ';
}
.lst-kix_b57g1j47pg3v-7 > li:before {
	content: '' counter(lst-ctn-kix_b57g1j47pg3v-0, decimal) '.'
		counter(lst-ctn-kix_b57g1j47pg3v-1, decimal) '.'
		counter(lst-ctn-kix_b57g1j47pg3v-2, decimal) '.'
		counter(lst-ctn-kix_b57g1j47pg3v-3, decimal) '.'
		counter(lst-ctn-kix_b57g1j47pg3v-4, decimal) '.'
		counter(lst-ctn-kix_b57g1j47pg3v-5, decimal) '.'
		counter(lst-ctn-kix_b57g1j47pg3v-6, decimal) '.'
		counter(lst-ctn-kix_b57g1j47pg3v-7, decimal) '. ';
}
ol.lst-kix_b57g1j47pg3v-8.start {
	counter-reset: lst-ctn-kix_b57g1j47pg3v-8 0;
}
.lst-kix_s4zdcr4jvz42-5 > li {
	counter-increment: lst-ctn-kix_s4zdcr4jvz42-5;
}
.lst-kix_list_1-1 > li {
	counter-increment: lst-ctn-kix_list_1-1;
}
.lst-kix_b57g1j47pg3v-2 > li:before {
	content: '' counter(lst-ctn-kix_b57g1j47pg3v-0, decimal) '.'
		counter(lst-ctn-kix_b57g1j47pg3v-1, decimal) '.'
		counter(lst-ctn-kix_b57g1j47pg3v-2, decimal) '. ';
}
ol.lst-kix_list_2-6.start {
	counter-reset: lst-ctn-kix_list_2-6 0;
}
.lst-kix_list_3-0 > li:before {
	content: '-  ';
}
ol.lst-kix_q01hydxackv6-4.start {
	counter-reset: lst-ctn-kix_q01hydxackv6-4 0;
}
ol.lst-kix_b5xso8t77fn6-6.start {
	counter-reset: lst-ctn-kix_b5xso8t77fn6-6 0;
}
.lst-kix_63mk0cxee5lz-0 > li:before {
	content: '' counter(lst-ctn-kix_63mk0cxee5lz-0, decimal) '. ';
}
ol.lst-kix_63mk0cxee5lz-4.start {
	counter-reset: lst-ctn-kix_63mk0cxee5lz-4 0;
}
.lst-kix_q01hydxackv6-7 > li {
	counter-increment: lst-ctn-kix_q01hydxackv6-7;
}
.lst-kix_list_3-4 > li:before {
	content: 'o  ';
}
.lst-kix_list_3-3 > li:before {
	content: '\0025cf  ';
}
.lst-kix_b57g1j47pg3v-6 > li:before {
	content: '' counter(lst-ctn-kix_b57g1j47pg3v-0, decimal) '.'
		counter(lst-ctn-kix_b57g1j47pg3v-1, decimal) '.'
		counter(lst-ctn-kix_b57g1j47pg3v-2, decimal) '.'
		counter(lst-ctn-kix_b57g1j47pg3v-3, decimal) '.'
		counter(lst-ctn-kix_b57g1j47pg3v-4, decimal) '.'
		counter(lst-ctn-kix_b57g1j47pg3v-5, decimal) '.'
		counter(lst-ctn-kix_b57g1j47pg3v-6, decimal) '. ';
}
.lst-kix_b57g1j47pg3v-8 > li {
	counter-increment: lst-ctn-kix_b57g1j47pg3v-8;
}
.lst-kix_list_3-8 > li:before {
	content: '\0025aa  ';
}
ol.lst-kix_s4zdcr4jvz42-6 {
	list-style-type: none;
}
ol.lst-kix_s4zdcr4jvz42-5 {
	list-style-type: none;
}
ol.lst-kix_s4zdcr4jvz42-8 {
	list-style-type: none;
}
ol.lst-kix_s4zdcr4jvz42-7 {
	list-style-type: none;
}
.lst-kix_list_3-7 > li:before {
	content: 'o  ';
}
.lst-kix_q01hydxackv6-5 > li {
	counter-increment: lst-ctn-kix_q01hydxackv6-5;
}
ol.lst-kix_s4zdcr4jvz42-0 {
	list-style-type: none;
}
ol.lst-kix_ukbavo85rgyb-7.start {
	counter-reset: lst-ctn-kix_ukbavo85rgyb-7 0;
}
ol.lst-kix_s4zdcr4jvz42-2 {
	list-style-type: none;
}
ol.lst-kix_s4zdcr4jvz42-1 {
	list-style-type: none;
}
ol.lst-kix_s4zdcr4jvz42-4 {
	list-style-type: none;
}
ol.lst-kix_s4zdcr4jvz42-3 {
	list-style-type: none;
}
ol.lst-kix_ccpf54yj8ft4-7.start {
	counter-reset: lst-ctn-kix_ccpf54yj8ft4-7 0;
}
ol.lst-kix_list_2-2 {
	list-style-type: none;
}
ol.lst-kix_list_2-3 {
	list-style-type: none;
}
ol.lst-kix_list_2-4 {
	list-style-type: none;
}
ol.lst-kix_list_2-5 {
	list-style-type: none;
}
ol.lst-kix_list_2-0 {
	list-style-type: none;
}
ol.lst-kix_list_2-1 {
	list-style-type: none;
}
.lst-kix_b5xso8t77fn6-3 > li {
	counter-increment: lst-ctn-kix_b5xso8t77fn6-3;
}
ol.lst-kix_63mk0cxee5lz-5.start {
	counter-reset: lst-ctn-kix_63mk0cxee5lz-5 0;
}
.lst-kix_b5xso8t77fn6-0 > li:before {
	content: '' counter(lst-ctn-kix_b5xso8t77fn6-0, lower-latin) ') ';
}
.lst-kix_b5xso8t77fn6-1 > li:before {
	content: '' counter(lst-ctn-kix_b5xso8t77fn6-1, lower-roman) ') ';
}
ol.lst-kix_ukbavo85rgyb-8.start {
	counter-reset: lst-ctn-kix_ukbavo85rgyb-8 0;
}
.lst-kix_s4zdcr4jvz42-1 > li {
	counter-increment: lst-ctn-kix_s4zdcr4jvz42-1;
}
ol.lst-kix_ukbavo85rgyb-1.start {
	counter-reset: lst-ctn-kix_ukbavo85rgyb-1 0;
}
ol.lst-kix_ccpf54yj8ft4-8.start {
	counter-reset: lst-ctn-kix_ccpf54yj8ft4-8 0;
}
.lst-kix_b5xso8t77fn6-5 > li:before {
	content: '(' counter(lst-ctn-kix_b5xso8t77fn6-5, decimal) ') ';
}
.lst-kix_ru1bpjfhdry7-6 > li {
	counter-increment: lst-ctn-kix_ru1bpjfhdry7-6;
}
ol.lst-kix_list_2-6 {
	list-style-type: none;
}
ol.lst-kix_list_2-7 {
	list-style-type: none;
}
ol.lst-kix_list_2-8 {
	list-style-type: none;
}
.lst-kix_b5xso8t77fn6-4 > li:before {
	content: '(' counter(lst-ctn-kix_b5xso8t77fn6-4, lower-roman) ') ';
}
ol.lst-kix_ukbavo85rgyb-5 {
	list-style-type: none;
}
.lst-kix_63mk0cxee5lz-7 > li:before {
	content: '' counter(lst-ctn-kix_63mk0cxee5lz-0, decimal) '.'
		counter(lst-ctn-kix_63mk0cxee5lz-1, decimal) '.'
		counter(lst-ctn-kix_63mk0cxee5lz-2, decimal) '.'
		counter(lst-ctn-kix_63mk0cxee5lz-3, decimal) '.'
		counter(lst-ctn-kix_63mk0cxee5lz-4, decimal) '.'
		counter(lst-ctn-kix_63mk0cxee5lz-5, decimal) '.'
		counter(lst-ctn-kix_63mk0cxee5lz-6, decimal) '.'
		counter(lst-ctn-kix_63mk0cxee5lz-7, decimal) '. ';
}
ol.lst-kix_ukbavo85rgyb-4 {
	list-style-type: none;
}
ol.lst-kix_ukbavo85rgyb-3 {
	list-style-type: none;
}
.lst-kix_63mk0cxee5lz-8 > li:before {
	content: '' counter(lst-ctn-kix_63mk0cxee5lz-0, decimal) '.'
		counter(lst-ctn-kix_63mk0cxee5lz-1, decimal) '.'
		counter(lst-ctn-kix_63mk0cxee5lz-2, decimal) '.'
		counter(lst-ctn-kix_63mk0cxee5lz-3, decimal) '.'
		counter(lst-ctn-kix_63mk0cxee5lz-4, decimal) '.'
		counter(lst-ctn-kix_63mk0cxee5lz-5, decimal) '.'
		counter(lst-ctn-kix_63mk0cxee5lz-6, decimal) '.'
		counter(lst-ctn-kix_63mk0cxee5lz-7, decimal) '.'
		counter(lst-ctn-kix_63mk0cxee5lz-8, decimal) '. ';
}
ol.lst-kix_ukbavo85rgyb-2 {
	list-style-type: none;
}
ol.lst-kix_ukbavo85rgyb-1 {
	list-style-type: none;
}
ol.lst-kix_ukbavo85rgyb-0 {
	list-style-type: none;
}
.lst-kix_63mk0cxee5lz-3 > li:before {
	content: '' counter(lst-ctn-kix_63mk0cxee5lz-0, decimal) '.'
		counter(lst-ctn-kix_63mk0cxee5lz-1, decimal) '.'
		counter(lst-ctn-kix_63mk0cxee5lz-2, decimal) '.'
		counter(lst-ctn-kix_63mk0cxee5lz-3, decimal) '. ';
}
.lst-kix_63mk0cxee5lz-4 > li:before {
	content: '' counter(lst-ctn-kix_63mk0cxee5lz-0, decimal) '.'
		counter(lst-ctn-kix_63mk0cxee5lz-1, decimal) '.'
		counter(lst-ctn-kix_63mk0cxee5lz-2, decimal) '.'
		counter(lst-ctn-kix_63mk0cxee5lz-3, decimal) '.'
		counter(lst-ctn-kix_63mk0cxee5lz-4, decimal) '. ';
}
ol.lst-kix_ukbavo85rgyb-8 {
	list-style-type: none;
}
ol.lst-kix_ukbavo85rgyb-7 {
	list-style-type: none;
}
.lst-kix_b5xso8t77fn6-8 > li:before {
	content: '' counter(lst-ctn-kix_b5xso8t77fn6-8, decimal) '. ';
}
ol.lst-kix_ukbavo85rgyb-6 {
	list-style-type: none;
}
ol.lst-kix_q01hydxackv6-5.start {
	counter-reset: lst-ctn-kix_q01hydxackv6-5 0;
}
ol.lst-kix_ukbavo85rgyb-2.start {
	counter-reset: lst-ctn-kix_ukbavo85rgyb-2 0;
}
.lst-kix_list_2-2 > li {
	counter-increment: lst-ctn-kix_list_2-2;
}
.lst-kix_q01hydxackv6-3 > li:before {
	content: '' counter(lst-ctn-kix_q01hydxackv6-0, decimal) '.'
		counter(lst-ctn-kix_q01hydxackv6-1, decimal) '.'
		counter(lst-ctn-kix_q01hydxackv6-2, decimal) '.'
		counter(lst-ctn-kix_q01hydxackv6-3, decimal) '. ';
}
ol.lst-kix_63mk0cxee5lz-0.start {
	counter-reset: lst-ctn-kix_63mk0cxee5lz-0 0;
}
.lst-kix_list_2-4 > li:before {
	content: '' counter(lst-ctn-kix_list_2-0, decimal) '.'
		counter(lst-ctn-kix_list_2-1, decimal) '.'
		counter(lst-ctn-kix_list_2-2, decimal) '.'
		counter(lst-ctn-kix_list_2-3, decimal) '.'
		counter(lst-ctn-kix_list_2-4, decimal) '. ';
}
.lst-kix_list_2-8 > li:before {
	content: '' counter(lst-ctn-kix_list_2-0, decimal) '.'
		counter(lst-ctn-kix_list_2-1, decimal) '.'
		counter(lst-ctn-kix_list_2-2, decimal) '.'
		counter(lst-ctn-kix_list_2-3, decimal) '.'
		counter(lst-ctn-kix_list_2-4, decimal) '.'
		counter(lst-ctn-kix_list_2-5, decimal) '.'
		counter(lst-ctn-kix_list_2-6, decimal) '.'
		counter(lst-ctn-kix_list_2-7, decimal) '.'
		counter(lst-ctn-kix_list_2-8, decimal) '. ';
}
ol.lst-kix_b57g1j47pg3v-1.start {
	counter-reset: lst-ctn-kix_b57g1j47pg3v-1 0;
}
ol.lst-kix_ukbavo85rgyb-3.start {
	counter-reset: lst-ctn-kix_ukbavo85rgyb-3 0;
}
ol.lst-kix_q01hydxackv6-0.start {
	counter-reset: lst-ctn-kix_q01hydxackv6-0 0;
}
.lst-kix_b57g1j47pg3v-3 > li {
	counter-increment: lst-ctn-kix_b57g1j47pg3v-3;
}
.lst-kix_q01hydxackv6-7 > li:before {
	content: '' counter(lst-ctn-kix_q01hydxackv6-0, decimal) '.'
		counter(lst-ctn-kix_q01hydxackv6-1, decimal) '.'
		counter(lst-ctn-kix_q01hydxackv6-2, decimal) '.'
		counter(lst-ctn-kix_q01hydxackv6-3, decimal) '.'
		counter(lst-ctn-kix_q01hydxackv6-4, decimal) '.'
		counter(lst-ctn-kix_q01hydxackv6-5, decimal) '.'
		counter(lst-ctn-kix_q01hydxackv6-6, decimal) '.'
		counter(lst-ctn-kix_q01hydxackv6-7, decimal) '. ';
}
.lst-kix_s4zdcr4jvz42-2 > li:before {
	content: '' counter(lst-ctn-kix_s4zdcr4jvz42-2, lower-roman) '. ';
}
.lst-kix_hh5nrmz5lj4w-3 > li {
	counter-increment: lst-ctn-kix_hh5nrmz5lj4w-3;
}
ol.lst-kix_ukbavo85rgyb-6.start {
	counter-reset: lst-ctn-kix_ukbavo85rgyb-6 0;
}
.lst-kix_b57g1j47pg3v-5 > li {
	counter-increment: lst-ctn-kix_b57g1j47pg3v-5;
}
.lst-kix_ukbavo85rgyb-8 > li {
	counter-increment: lst-ctn-kix_ukbavo85rgyb-8;
}
ol.lst-kix_q01hydxackv6-3.start {
	counter-reset: lst-ctn-kix_q01hydxackv6-3 0;
}
ol.lst-kix_b5xso8t77fn6-5.start {
	counter-reset: lst-ctn-kix_b5xso8t77fn6-5 0;
}
ol.lst-kix_list_2-2.start {
	counter-reset: lst-ctn-kix_list_2-2 0;
}
ol.lst-kix_b57g1j47pg3v-4.start {
	counter-reset: lst-ctn-kix_b57g1j47pg3v-4 0;
}
ol.lst-kix_q01hydxackv6-2.start {
	counter-reset: lst-ctn-kix_q01hydxackv6-2 0;
}
.lst-kix_ru1bpjfhdry7-3 > li:before {
	content: '' counter(lst-ctn-kix_ru1bpjfhdry7-0, decimal) '.'
		counter(lst-ctn-kix_ru1bpjfhdry7-1, decimal) '.'
		counter(lst-ctn-kix_ru1bpjfhdry7-2, decimal) '.'
		counter(lst-ctn-kix_ru1bpjfhdry7-3, decimal) '. ';
}
.lst-kix_ru1bpjfhdry7-7 > li:before {
	content: '' counter(lst-ctn-kix_ru1bpjfhdry7-0, decimal) '.'
		counter(lst-ctn-kix_ru1bpjfhdry7-1, decimal) '.'
		counter(lst-ctn-kix_ru1bpjfhdry7-2, decimal) '.'
		counter(lst-ctn-kix_ru1bpjfhdry7-3, decimal) '.'
		counter(lst-ctn-kix_ru1bpjfhdry7-4, decimal) '.'
		counter(lst-ctn-kix_ru1bpjfhdry7-5, decimal) '.'
		counter(lst-ctn-kix_ru1bpjfhdry7-6, decimal) '.'
		counter(lst-ctn-kix_ru1bpjfhdry7-7, decimal) '. ';
}
ol.lst-kix_b57g1j47pg3v-3.start {
	counter-reset: lst-ctn-kix_b57g1j47pg3v-3 0;
}
.lst-kix_list_2-4 > li {
	counter-increment: lst-ctn-kix_list_2-4;
}
ol.lst-kix_ukbavo85rgyb-5.start {
	counter-reset: lst-ctn-kix_ukbavo85rgyb-5 0;
}
ol.lst-kix_63mk0cxee5lz-2.start {
	counter-reset: lst-ctn-kix_63mk0cxee5lz-2 0;
}
ol.lst-kix_b5xso8t77fn6-8.start {
	counter-reset: lst-ctn-kix_b5xso8t77fn6-8 0;
}
.lst-kix_ccpf54yj8ft4-0 > li:before {
	content: '' counter(lst-ctn-kix_ccpf54yj8ft4-0, decimal) '. ';
}
.lst-kix_list_1-0 > li:before {
	content: '' counter(lst-ctn-kix_list_1-0, lower-latin) ') ';
}
ol.lst-kix_list_2-0.start {
	counter-reset: lst-ctn-kix_list_2-0 0;
}
.lst-kix_hh5nrmz5lj4w-8 > li {
	counter-increment: lst-ctn-kix_hh5nrmz5lj4w-8;
}
.lst-kix_list_1-4 > li:before {
	content: '(' counter(lst-ctn-kix_list_1-4, lower-roman) ') ';
}
.lst-kix_s4zdcr4jvz42-3 > li {
	counter-increment: lst-ctn-kix_s4zdcr4jvz42-3;
}
.lst-kix_s4zdcr4jvz42-6 > li:before {
	content: '' counter(lst-ctn-kix_s4zdcr4jvz42-6, decimal) '. ';
}
.lst-kix_hh5nrmz5lj4w-3 > li:before {
	content: '(' counter(lst-ctn-kix_hh5nrmz5lj4w-3, lower-latin) ') ';
}
.lst-kix_list_1-6 > li {
	counter-increment: lst-ctn-kix_list_1-6;
}
ol.lst-kix_b57g1j47pg3v-2.start {
	counter-reset: lst-ctn-kix_b57g1j47pg3v-2 0;
}
.lst-kix_q01hydxackv6-0 > li {
	counter-increment: lst-ctn-kix_q01hydxackv6-0;
}
.lst-kix_b5xso8t77fn6-5 > li {
	counter-increment: lst-ctn-kix_b5xso8t77fn6-5;
}
.lst-kix_hh5nrmz5lj4w-7 > li:before {
	content: '' counter(lst-ctn-kix_hh5nrmz5lj4w-7, lower-roman) '. ';
}
.lst-kix_list_2-0 > li:before {
	content: '' counter(lst-ctn-kix_list_2-0, decimal) '. ';
}
ol.lst-kix_list_2-1.start {
	counter-reset: lst-ctn-kix_list_2-1 0;
}
ol.lst-kix_b5xso8t77fn6-7.start {
	counter-reset: lst-ctn-kix_b5xso8t77fn6-7 0;
}
.lst-kix_ru1bpjfhdry7-1 > li {
	counter-increment: lst-ctn-kix_ru1bpjfhdry7-1;
}
.lst-kix_list_1-8 > li:before {
	content: '' counter(lst-ctn-kix_list_1-8, decimal) '. ';
}
ol.lst-kix_63mk0cxee5lz-3.start {
	counter-reset: lst-ctn-kix_63mk0cxee5lz-3 0;
}
ol.lst-kix_q01hydxackv6-7.start {
	counter-reset: lst-ctn-kix_q01hydxackv6-7 0;
}
ol.lst-kix_s4zdcr4jvz42-4.start {
	counter-reset: lst-ctn-kix_s4zdcr4jvz42-4 0;
}
.lst-kix_ukbavo85rgyb-3 > li {
	counter-increment: lst-ctn-kix_ukbavo85rgyb-3;
}
.lst-kix_q01hydxackv6-8 > li {
	counter-increment: lst-ctn-kix_q01hydxackv6-8;
}
.lst-kix_63mk0cxee5lz-2 > li {
	counter-increment: lst-ctn-kix_63mk0cxee5lz-2;
}
ol.lst-kix_hh5nrmz5lj4w-7.start {
	counter-reset: lst-ctn-kix_hh5nrmz5lj4w-7 0;
}
ol.lst-kix_list_1-5.start {
	counter-reset: lst-ctn-kix_list_1-5 0;
}
.lst-kix_ccpf54yj8ft4-7 > li:before {
	content: '' counter(lst-ctn-kix_ccpf54yj8ft4-0, decimal) '.'
		counter(lst-ctn-kix_ccpf54yj8ft4-1, decimal) '.'
		counter(lst-ctn-kix_ccpf54yj8ft4-2, decimal) '.'
		counter(lst-ctn-kix_ccpf54yj8ft4-3, decimal) '.'
		counter(lst-ctn-kix_ccpf54yj8ft4-4, decimal) '.'
		counter(lst-ctn-kix_ccpf54yj8ft4-5, decimal) '.'
		counter(lst-ctn-kix_ccpf54yj8ft4-6, decimal) '.'
		counter(lst-ctn-kix_ccpf54yj8ft4-7, decimal) '. ';
}
.lst-kix_ccpf54yj8ft4-6 > li:before {
	content: '' counter(lst-ctn-kix_ccpf54yj8ft4-0, decimal) '.'
		counter(lst-ctn-kix_ccpf54yj8ft4-1, decimal) '.'
		counter(lst-ctn-kix_ccpf54yj8ft4-2, decimal) '.'
		counter(lst-ctn-kix_ccpf54yj8ft4-3, decimal) '.'
		counter(lst-ctn-kix_ccpf54yj8ft4-4, decimal) '.'
		counter(lst-ctn-kix_ccpf54yj8ft4-5, decimal) '.'
		counter(lst-ctn-kix_ccpf54yj8ft4-6, decimal) '. ';
}
.lst-kix_ccpf54yj8ft4-8 > li:before {
	content: '' counter(lst-ctn-kix_ccpf54yj8ft4-0, decimal) '.'
		counter(lst-ctn-kix_ccpf54yj8ft4-1, decimal) '.'
		counter(lst-ctn-kix_ccpf54yj8ft4-2, decimal) '.'
		counter(lst-ctn-kix_ccpf54yj8ft4-3, decimal) '.'
		counter(lst-ctn-kix_ccpf54yj8ft4-4, decimal) '.'
		counter(lst-ctn-kix_ccpf54yj8ft4-5, decimal) '.'
		counter(lst-ctn-kix_ccpf54yj8ft4-6, decimal) '.'
		counter(lst-ctn-kix_ccpf54yj8ft4-7, decimal) '.'
		counter(lst-ctn-kix_ccpf54yj8ft4-8, decimal) '. ';
}
.lst-kix_list_2-3 > li {
	counter-increment: lst-ctn-kix_list_2-3;
}
.lst-kix_ccpf54yj8ft4-4 > li:before {
	content: '' counter(lst-ctn-kix_ccpf54yj8ft4-0, decimal) '.'
		counter(lst-ctn-kix_ccpf54yj8ft4-1, decimal) '.'
		counter(lst-ctn-kix_ccpf54yj8ft4-2, decimal) '.'
		counter(lst-ctn-kix_ccpf54yj8ft4-3, decimal) '.'
		counter(lst-ctn-kix_ccpf54yj8ft4-4, decimal) '. ';
}
ol.lst-kix_ukbavo85rgyb-4.start {
	counter-reset: lst-ctn-kix_ukbavo85rgyb-4 0;
}
.lst-kix_ccpf54yj8ft4-5 > li:before {
	content: '' counter(lst-ctn-kix_ccpf54yj8ft4-0, decimal) '.'
		counter(lst-ctn-kix_ccpf54yj8ft4-1, decimal) '.'
		counter(lst-ctn-kix_ccpf54yj8ft4-2, decimal) '.'
		counter(lst-ctn-kix_ccpf54yj8ft4-3, decimal) '.'
		counter(lst-ctn-kix_ccpf54yj8ft4-4, decimal) '.'
		counter(lst-ctn-kix_ccpf54yj8ft4-5, decimal) '. ';
}
ol.lst-kix_63mk0cxee5lz-1.start {
	counter-reset: lst-ctn-kix_63mk0cxee5lz-1 0;
}
.lst-kix_list_1-2 > li {
	counter-increment: lst-ctn-kix_list_1-2;
}
.lst-kix_ccpf54yj8ft4-6 > li {
	counter-increment: lst-ctn-kix_ccpf54yj8ft4-6;
}
.lst-kix_ru1bpjfhdry7-5 > li {
	counter-increment: lst-ctn-kix_ru1bpjfhdry7-5;
}
.lst-kix_s4zdcr4jvz42-2 > li {
	counter-increment: lst-ctn-kix_s4zdcr4jvz42-2;
}
.lst-kix_b57g1j47pg3v-0 > li {
	counter-increment: lst-ctn-kix_b57g1j47pg3v-0;
}
.lst-kix_hh5nrmz5lj4w-6 > li {
	counter-increment: lst-ctn-kix_hh5nrmz5lj4w-6;
}
.lst-kix_q01hydxackv6-4 > li {
	counter-increment: lst-ctn-kix_q01hydxackv6-4;
}
ol.lst-kix_b57g1j47pg3v-0 {
	list-style-type: none;
}
ol.lst-kix_list_1-0.start {
	counter-reset: lst-ctn-kix_list_1-0 0;
}
ol.lst-kix_b57g1j47pg3v-1 {
	list-style-type: none;
}
ol.lst-kix_63mk0cxee5lz-6.start {
	counter-reset: lst-ctn-kix_63mk0cxee5lz-6 0;
}
ol.lst-kix_b57g1j47pg3v-2 {
	list-style-type: none;
}
ol.lst-kix_b57g1j47pg3v-3 {
	list-style-type: none;
}
ol.lst-kix_b57g1j47pg3v-4 {
	list-style-type: none;
}
ol.lst-kix_b57g1j47pg3v-5 {
	list-style-type: none;
}
ol.lst-kix_b57g1j47pg3v-6 {
	list-style-type: none;
}
ol.lst-kix_b57g1j47pg3v-7 {
	list-style-type: none;
}
ol.lst-kix_b57g1j47pg3v-8 {
	list-style-type: none;
}
ol.lst-kix_b57g1j47pg3v-6.start {
	counter-reset: lst-ctn-kix_b57g1j47pg3v-6 0;
}
ol.lst-kix_ru1bpjfhdry7-5.start {
	counter-reset: lst-ctn-kix_ru1bpjfhdry7-5 0;
}
.lst-kix_q01hydxackv6-0 > li:before {
	content: '' counter(lst-ctn-kix_q01hydxackv6-0, decimal) '. ';
}
.lst-kix_q01hydxackv6-1 > li:before {
	content: '' counter(lst-ctn-kix_q01hydxackv6-0, decimal) '.'
		counter(lst-ctn-kix_q01hydxackv6-1, decimal) '. ';
}
ol.lst-kix_list_2-4.start {
	counter-reset: lst-ctn-kix_list_2-4 0;
}
.lst-kix_ukbavo85rgyb-7 > li {
	counter-increment: lst-ctn-kix_ukbavo85rgyb-7;
}
ol.lst-kix_b5xso8t77fn6-4.start {
	counter-reset: lst-ctn-kix_b5xso8t77fn6-4 0;
}
ol.lst-kix_list_1-3 {
	list-style-type: none;
}
.lst-kix_q01hydxackv6-4 > li:before {
	content: '' counter(lst-ctn-kix_q01hydxackv6-0, decimal) '.'
		counter(lst-ctn-kix_q01hydxackv6-1, decimal) '.'
		counter(lst-ctn-kix_q01hydxackv6-2, decimal) '.'
		counter(lst-ctn-kix_q01hydxackv6-3, decimal) '.'
		counter(lst-ctn-kix_q01hydxackv6-4, decimal) '. ';
}
ol.lst-kix_list_1-4 {
	list-style-type: none;
}
.lst-kix_list_2-7 > li:before {
	content: '' counter(lst-ctn-kix_list_2-0, decimal) '.'
		counter(lst-ctn-kix_list_2-1, decimal) '.'
		counter(lst-ctn-kix_list_2-2, decimal) '.'
		counter(lst-ctn-kix_list_2-3, decimal) '.'
		counter(lst-ctn-kix_list_2-4, decimal) '.'
		counter(lst-ctn-kix_list_2-5, decimal) '.'
		counter(lst-ctn-kix_list_2-6, decimal) '.'
		counter(lst-ctn-kix_list_2-7, decimal) '. ';
}
.lst-kix_list_2-7 > li {
	counter-increment: lst-ctn-kix_list_2-7;
}
ol.lst-kix_list_1-5 {
	list-style-type: none;
}
ol.lst-kix_list_1-6 {
	list-style-type: none;
}
.lst-kix_q01hydxackv6-2 > li:before {
	content: '' counter(lst-ctn-kix_q01hydxackv6-0, decimal) '.'
		counter(lst-ctn-kix_q01hydxackv6-1, decimal) '.'
		counter(lst-ctn-kix_q01hydxackv6-2, decimal) '. ';
}
ol.lst-kix_list_1-0 {
	list-style-type: none;
}
.lst-kix_list_2-5 > li:before {
	content: '' counter(lst-ctn-kix_list_2-0, decimal) '.'
		counter(lst-ctn-kix_list_2-1, decimal) '.'
		counter(lst-ctn-kix_list_2-2, decimal) '.'
		counter(lst-ctn-kix_list_2-3, decimal) '.'
		counter(lst-ctn-kix_list_2-4, decimal) '.'
		counter(lst-ctn-kix_list_2-5, decimal) '. ';
}
.lst-kix_q01hydxackv6-6 > li:before {
	content: '' counter(lst-ctn-kix_q01hydxackv6-0, decimal) '.'
		counter(lst-ctn-kix_q01hydxackv6-1, decimal) '.'
		counter(lst-ctn-kix_q01hydxackv6-2, decimal) '.'
		counter(lst-ctn-kix_q01hydxackv6-3, decimal) '.'
		counter(lst-ctn-kix_q01hydxackv6-4, decimal) '.'
		counter(lst-ctn-kix_q01hydxackv6-5, decimal) '.'
		counter(lst-ctn-kix_q01hydxackv6-6, decimal) '. ';
}
ol.lst-kix_list_1-1 {
	list-style-type: none;
}
ol.lst-kix_list_1-2 {
	list-style-type: none;
}
ul.lst-kix_list_3-7 {
	list-style-type: none;
}
ul.lst-kix_list_3-8 {
	list-style-type: none;
}
ul.lst-kix_list_3-1 {
	list-style-type: none;
}
ul.lst-kix_list_3-2 {
	list-style-type: none;
}
ol.lst-kix_ccpf54yj8ft4-3.start {
	counter-reset: lst-ctn-kix_ccpf54yj8ft4-3 0;
}
ul.lst-kix_list_3-0 {
	list-style-type: none;
}
ol.lst-kix_list_1-7 {
	list-style-type: none;
}
ul.lst-kix_list_3-5 {
	list-style-type: none;
}
ol.lst-kix_list_1-8 {
	list-style-type: none;
}
ul.lst-kix_list_3-6 {
	list-style-type: none;
}
.lst-kix_q01hydxackv6-8 > li:before {
	content: '' counter(lst-ctn-kix_q01hydxackv6-0, decimal) '.'
		counter(lst-ctn-kix_q01hydxackv6-1, decimal) '.'
		counter(lst-ctn-kix_q01hydxackv6-2, decimal) '.'
		counter(lst-ctn-kix_q01hydxackv6-3, decimal) '.'
		counter(lst-ctn-kix_q01hydxackv6-4, decimal) '.'
		counter(lst-ctn-kix_q01hydxackv6-5, decimal) '.'
		counter(lst-ctn-kix_q01hydxackv6-6, decimal) '.'
		counter(lst-ctn-kix_q01hydxackv6-7, decimal) '.'
		counter(lst-ctn-kix_q01hydxackv6-8, decimal) '. ';
}
ul.lst-kix_list_3-3 {
	list-style-type: none;
}
ul.lst-kix_list_3-4 {
	list-style-type: none;
}
.lst-kix_s4zdcr4jvz42-1 > li:before {
	content: '' counter(lst-ctn-kix_s4zdcr4jvz42-1, lower-latin) '. ';
}
.lst-kix_list_2-6 > li {
	counter-increment: lst-ctn-kix_list_2-6;
}
.lst-kix_ccpf54yj8ft4-3 > li {
	counter-increment: lst-ctn-kix_ccpf54yj8ft4-3;
}
ol.lst-kix_b5xso8t77fn6-2.start {
	counter-reset: lst-ctn-kix_b5xso8t77fn6-2 0;
}
.lst-kix_ukbavo85rgyb-0 > li {
	counter-increment: lst-ctn-kix_ukbavo85rgyb-0;
}
ol.lst-kix_hh5nrmz5lj4w-0.start {
	counter-reset: lst-ctn-kix_hh5nrmz5lj4w-0 0;
}
ol.lst-kix_63mk0cxee5lz-8.start {
	counter-reset: lst-ctn-kix_63mk0cxee5lz-8 0;
}
.lst-kix_ukbavo85rgyb-6 > li {
	counter-increment: lst-ctn-kix_ukbavo85rgyb-6;
}
ol.lst-kix_list_1-3.start {
	counter-reset: lst-ctn-kix_list_1-3 0;
}
ol.lst-kix_s4zdcr4jvz42-6.start {
	counter-reset: lst-ctn-kix_s4zdcr4jvz42-6 0;
}
ol.lst-kix_list_1-2.start {
	counter-reset: lst-ctn-kix_list_1-2 0;
}
ol.lst-kix_ru1bpjfhdry7-7.start {
	counter-reset: lst-ctn-kix_ru1bpjfhdry7-7 0;
}
.lst-kix_ccpf54yj8ft4-3 > li:before {
	content: '' counter(lst-ctn-kix_ccpf54yj8ft4-0, decimal) '.'
		counter(lst-ctn-kix_ccpf54yj8ft4-1, decimal) '.'
		counter(lst-ctn-kix_ccpf54yj8ft4-2, decimal) '.'
		counter(lst-ctn-kix_ccpf54yj8ft4-3, decimal) '. ';
}
.lst-kix_ccpf54yj8ft4-1 > li:before {
	content: '' counter(lst-ctn-kix_ccpf54yj8ft4-0, decimal) '.'
		counter(lst-ctn-kix_ccpf54yj8ft4-1, decimal) '. ';
}
.lst-kix_hh5nrmz5lj4w-0 > li:before {
	content: '' counter(lst-ctn-kix_hh5nrmz5lj4w-0, lower-latin) ') ';
}
.lst-kix_b57g1j47pg3v-7 > li {
	counter-increment: lst-ctn-kix_b57g1j47pg3v-7;
}
.lst-kix_list_1-1 > li:before {
	content: '' counter(lst-ctn-kix_list_1-1, lower-roman) ') ';
}
.lst-kix_hh5nrmz5lj4w-2 > li:before {
	content: '' counter(lst-ctn-kix_hh5nrmz5lj4w-2, decimal) ') ';
}
.lst-kix_list_1-3 > li:before {
	content: '(' counter(lst-ctn-kix_list_1-3, lower-latin) ') ';
}
.lst-kix_s4zdcr4jvz42-7 > li:before {
	content: '' counter(lst-ctn-kix_s4zdcr4jvz42-7, lower-latin) '. ';
}
.lst-kix_s4zdcr4jvz42-3 > li:before {
	content: '' counter(lst-ctn-kix_s4zdcr4jvz42-3, decimal) '. ';
}
ol.lst-kix_ccpf54yj8ft4-1 {
	list-style-type: none;
}
ol.lst-kix_ccpf54yj8ft4-2 {
	list-style-type: none;
}
.lst-kix_list_1-7 > li:before {
	content: '' counter(lst-ctn-kix_list_1-7, lower-roman) '. ';
}
ol.lst-kix_list_2-7.start {
	counter-reset: lst-ctn-kix_list_2-7 0;
}
ol.lst-kix_hh5nrmz5lj4w-2.start {
	counter-reset: lst-ctn-kix_hh5nrmz5lj4w-2 0;
}
ol.lst-kix_ccpf54yj8ft4-0 {
	list-style-type: none;
}
.lst-kix_b5xso8t77fn6-2 > li {
	counter-increment: lst-ctn-kix_b5xso8t77fn6-2;
}
ol.lst-kix_ccpf54yj8ft4-5.start {
	counter-reset: lst-ctn-kix_ccpf54yj8ft4-5 0;
}
.lst-kix_hh5nrmz5lj4w-4 > li:before {
	content: '(' counter(lst-ctn-kix_hh5nrmz5lj4w-4, lower-roman) ') ';
}
.lst-kix_list_1-5 > li:before {
	content: '(' counter(lst-ctn-kix_list_1-5, decimal) ') ';
}
.lst-kix_s4zdcr4jvz42-5 > li:before {
	content: '' counter(lst-ctn-kix_s4zdcr4jvz42-5, lower-roman) '. ';
}
ol.lst-kix_ccpf54yj8ft4-7 {
	list-style-type: none;
}
ol.lst-kix_ccpf54yj8ft4-8 {
	list-style-type: none;
}
ol.lst-kix_ccpf54yj8ft4-5 {
	list-style-type: none;
}
ol.lst-kix_ccpf54yj8ft4-6 {
	list-style-type: none;
}
.lst-kix_list_2-1 > li:before {
	content: '' counter(lst-ctn-kix_list_2-0, decimal) '.'
		counter(lst-ctn-kix_list_2-1, decimal) '. ';
}
.lst-kix_hh5nrmz5lj4w-6 > li:before {
	content: '' counter(lst-ctn-kix_hh5nrmz5lj4w-6, lower-latin) '. ';
}
ol.lst-kix_ccpf54yj8ft4-3 {
	list-style-type: none;
}
ol.lst-kix_ccpf54yj8ft4-4 {
	list-style-type: none;
}
.lst-kix_63mk0cxee5lz-6 > li {
	counter-increment: lst-ctn-kix_63mk0cxee5lz-6;
}
.lst-kix_ccpf54yj8ft4-2 > li {
	counter-increment: lst-ctn-kix_ccpf54yj8ft4-2;
}
.lst-kix_list_2-3 > li:before {
	content: '' counter(lst-ctn-kix_list_2-0, decimal) '.'
		counter(lst-ctn-kix_list_2-1, decimal) '.'
		counter(lst-ctn-kix_list_2-2, decimal) '.'
		counter(lst-ctn-kix_list_2-3, decimal) '. ';
}
ol.lst-kix_s4zdcr4jvz42-7.start {
	counter-reset: lst-ctn-kix_s4zdcr4jvz42-7 0;
}
ol.lst-kix_ru1bpjfhdry7-8.start {
	counter-reset: lst-ctn-kix_ru1bpjfhdry7-8 0;
}
.lst-kix_hh5nrmz5lj4w-8 > li:before {
	content: '' counter(lst-ctn-kix_hh5nrmz5lj4w-8, decimal) '. ';
}
.lst-kix_s4zdcr4jvz42-6 > li {
	counter-increment: lst-ctn-kix_s4zdcr4jvz42-6;
}
.lst-kix_b57g1j47pg3v-4 > li:before {
	content: '' counter(lst-ctn-kix_b57g1j47pg3v-0, decimal) '.'
		counter(lst-ctn-kix_b57g1j47pg3v-1, decimal) '.'
		counter(lst-ctn-kix_b57g1j47pg3v-2, decimal) '.'
		counter(lst-ctn-kix_b57g1j47pg3v-3, decimal) '.'
		counter(lst-ctn-kix_b57g1j47pg3v-4, decimal) '. ';
}
.lst-kix_b57g1j47pg3v-5 > li:before {
	content: '' counter(lst-ctn-kix_b57g1j47pg3v-0, decimal) '.'
		counter(lst-ctn-kix_b57g1j47pg3v-1, decimal) '.'
		counter(lst-ctn-kix_b57g1j47pg3v-2, decimal) '.'
		counter(lst-ctn-kix_b57g1j47pg3v-3, decimal) '.'
		counter(lst-ctn-kix_b57g1j47pg3v-4, decimal) '.'
		counter(lst-ctn-kix_b57g1j47pg3v-5, decimal) '. ';
}
ol.lst-kix_ru1bpjfhdry7-2.start {
	counter-reset: lst-ctn-kix_ru1bpjfhdry7-2 0;
}
.lst-kix_63mk0cxee5lz-1 > li {
	counter-increment: lst-ctn-kix_63mk0cxee5lz-1;
}
.lst-kix_b57g1j47pg3v-0 > li:before {
	content: '' counter(lst-ctn-kix_b57g1j47pg3v-0, decimal) '. ';
}
.lst-kix_b57g1j47pg3v-1 > li:before {
	content: '' counter(lst-ctn-kix_b57g1j47pg3v-0, decimal) '.'
		counter(lst-ctn-kix_b57g1j47pg3v-1, decimal) '. ';
}
.lst-kix_b57g1j47pg3v-8 > li:before {
	content: '' counter(lst-ctn-kix_b57g1j47pg3v-0, decimal) '.'
		counter(lst-ctn-kix_b57g1j47pg3v-1, decimal) '.'
		counter(lst-ctn-kix_b57g1j47pg3v-2, decimal) '.'
		counter(lst-ctn-kix_b57g1j47pg3v-3, decimal) '.'
		counter(lst-ctn-kix_b57g1j47pg3v-4, decimal) '.'
		counter(lst-ctn-kix_b57g1j47pg3v-5, decimal) '.'
		counter(lst-ctn-kix_b57g1j47pg3v-6, decimal) '.'
		counter(lst-ctn-kix_b57g1j47pg3v-7, decimal) '.'
		counter(lst-ctn-kix_b57g1j47pg3v-8, decimal) '. ';
}
.lst-kix_list_3-1 > li:before {
	content: 'o  ';
}
.lst-kix_list_3-2 > li:before {
	content: '\0025aa  ';
}
.lst-kix_63mk0cxee5lz-1 > li:before {
	content: '' counter(lst-ctn-kix_63mk0cxee5lz-0, decimal) '.'
		counter(lst-ctn-kix_63mk0cxee5lz-1, decimal) '. ';
}
ol.lst-kix_list_1-8.start {
	counter-reset: lst-ctn-kix_list_1-8 0;
}
ol.lst-kix_s4zdcr4jvz42-1.start {
	counter-reset: lst-ctn-kix_s4zdcr4jvz42-1 0;
}
.lst-kix_list_3-5 > li:before {
	content: '\0025aa  ';
}
.lst-kix_ccpf54yj8ft4-7 > li {
	counter-increment: lst-ctn-kix_ccpf54yj8ft4-7;
}
.lst-kix_ukbavo85rgyb-2 > li {
	counter-increment: lst-ctn-kix_ukbavo85rgyb-2;
}
.lst-kix_list_2-0 > li {
	counter-increment: lst-ctn-kix_list_2-0;
}
.lst-kix_list_3-6 > li:before {
	content: '\0025cf  ';
}
.lst-kix_b5xso8t77fn6-1 > li {
	counter-increment: lst-ctn-kix_b5xso8t77fn6-1;
}
ol.lst-kix_hh5nrmz5lj4w-5.start {
	counter-reset: lst-ctn-kix_hh5nrmz5lj4w-5 0;
}
.lst-kix_hh5nrmz5lj4w-7 > li {
	counter-increment: lst-ctn-kix_hh5nrmz5lj4w-7;
}
.lst-kix_b57g1j47pg3v-6 > li {
	counter-increment: lst-ctn-kix_b57g1j47pg3v-6;
}
.lst-kix_hh5nrmz5lj4w-0 > li {
	counter-increment: lst-ctn-kix_hh5nrmz5lj4w-0;
}
ol.lst-kix_ccpf54yj8ft4-1.start {
	counter-reset: lst-ctn-kix_ccpf54yj8ft4-1 0;
}
.lst-kix_s4zdcr4jvz42-7 > li {
	counter-increment: lst-ctn-kix_s4zdcr4jvz42-7;
}
.lst-kix_63mk0cxee5lz-5 > li {
	counter-increment: lst-ctn-kix_63mk0cxee5lz-5;
}
.lst-kix_b5xso8t77fn6-2 > li:before {
	content: '' counter(lst-ctn-kix_b5xso8t77fn6-2, decimal) ') ';
}
.lst-kix_b5xso8t77fn6-3 > li:before {
	content: '(' counter(lst-ctn-kix_b5xso8t77fn6-3, lower-latin) ') ';
}
ol.lst-kix_hh5nrmz5lj4w-4.start {
	counter-reset: lst-ctn-kix_hh5nrmz5lj4w-4 0;
}
.lst-kix_63mk0cxee5lz-6 > li:before {
	content: '' counter(lst-ctn-kix_63mk0cxee5lz-0, decimal) '.'
		counter(lst-ctn-kix_63mk0cxee5lz-1, decimal) '.'
		counter(lst-ctn-kix_63mk0cxee5lz-2, decimal) '.'
		counter(lst-ctn-kix_63mk0cxee5lz-3, decimal) '.'
		counter(lst-ctn-kix_63mk0cxee5lz-4, decimal) '.'
		counter(lst-ctn-kix_63mk0cxee5lz-5, decimal) '.'
		counter(lst-ctn-kix_63mk0cxee5lz-6, decimal) '. ';
}
.lst-kix_63mk0cxee5lz-5 > li:before {
	content: '' counter(lst-ctn-kix_63mk0cxee5lz-0, decimal) '.'
		counter(lst-ctn-kix_63mk0cxee5lz-1, decimal) '.'
		counter(lst-ctn-kix_63mk0cxee5lz-2, decimal) '.'
		counter(lst-ctn-kix_63mk0cxee5lz-3, decimal) '.'
		counter(lst-ctn-kix_63mk0cxee5lz-4, decimal) '.'
		counter(lst-ctn-kix_63mk0cxee5lz-5, decimal) '. ';
}
.lst-kix_b5xso8t77fn6-6 > li:before {
	content: '' counter(lst-ctn-kix_b5xso8t77fn6-6, lower-latin) '. ';
}
.lst-kix_63mk0cxee5lz-2 > li:before {
	content: '' counter(lst-ctn-kix_63mk0cxee5lz-0, decimal) '.'
		counter(lst-ctn-kix_63mk0cxee5lz-1, decimal) '.'
		counter(lst-ctn-kix_63mk0cxee5lz-2, decimal) '. ';
}
.lst-kix_b5xso8t77fn6-7 > li:before {
	content: '' counter(lst-ctn-kix_b5xso8t77fn6-7, lower-roman) '. ';
}
.lst-kix_ukbavo85rgyb-4 > li {
	counter-increment: lst-ctn-kix_ukbavo85rgyb-4;
}
ol.lst-kix_ccpf54yj8ft4-2.start {
	counter-reset: lst-ctn-kix_ccpf54yj8ft4-2 0;
}
.lst-kix_list_2-6 > li:before {
	content: '' counter(lst-ctn-kix_list_2-0, decimal) '.'
		counter(lst-ctn-kix_list_2-1, decimal) '.'
		counter(lst-ctn-kix_list_2-2, decimal) '.'
		counter(lst-ctn-kix_list_2-3, decimal) '.'
		counter(lst-ctn-kix_list_2-4, decimal) '.'
		counter(lst-ctn-kix_list_2-5, decimal) '.'
		counter(lst-ctn-kix_list_2-6, decimal) '. ';
}
ol.lst-kix_63mk0cxee5lz-1 {
	list-style-type: none;
}
.lst-kix_b5xso8t77fn6-6 > li {
	counter-increment: lst-ctn-kix_b5xso8t77fn6-6;
}
.lst-kix_ru1bpjfhdry7-2 > li {
	counter-increment: lst-ctn-kix_ru1bpjfhdry7-2;
}
ol.lst-kix_63mk0cxee5lz-0 {
	list-style-type: none;
}
ol.lst-kix_63mk0cxee5lz-3 {
	list-style-type: none;
}
ol.lst-kix_63mk0cxee5lz-2 {
	list-style-type: none;
}
ol.lst-kix_63mk0cxee5lz-5 {
	list-style-type: none;
}
ol.lst-kix_63mk0cxee5lz-4 {
	list-style-type: none;
}
ol.lst-kix_63mk0cxee5lz-7 {
	list-style-type: none;
}
ol.lst-kix_63mk0cxee5lz-6 {
	list-style-type: none;
}
ol.lst-kix_63mk0cxee5lz-8 {
	list-style-type: none;
}
.lst-kix_q01hydxackv6-5 > li:before {
	content: '' counter(lst-ctn-kix_q01hydxackv6-0, decimal) '.'
		counter(lst-ctn-kix_q01hydxackv6-1, decimal) '.'
		counter(lst-ctn-kix_q01hydxackv6-2, decimal) '.'
		counter(lst-ctn-kix_q01hydxackv6-3, decimal) '.'
		counter(lst-ctn-kix_q01hydxackv6-4, decimal) '.'
		counter(lst-ctn-kix_q01hydxackv6-5, decimal) '. ';
}
ol.lst-kix_s4zdcr4jvz42-0.start {
	counter-reset: lst-ctn-kix_s4zdcr4jvz42-0 0;
}
.lst-kix_63mk0cxee5lz-8 > li {
	counter-increment: lst-ctn-kix_63mk0cxee5lz-8;
}
.lst-kix_ccpf54yj8ft4-0 > li {
	counter-increment: lst-ctn-kix_ccpf54yj8ft4-0;
}
.lst-kix_b57g1j47pg3v-4 > li {
	counter-increment: lst-ctn-kix_b57g1j47pg3v-4;
}
.lst-kix_q01hydxackv6-1 > li {
	counter-increment: lst-ctn-kix_q01hydxackv6-1;
}
.lst-kix_s4zdcr4jvz42-0 > li:before {
	content: '' counter(lst-ctn-kix_s4zdcr4jvz42-0, decimal) '. ';
}
.lst-kix_list_1-7 > li {
	counter-increment: lst-ctn-kix_list_1-7;
}
.lst-kix_ukbavo85rgyb-8 > li:before {
	content: '' counter(lst-ctn-kix_ukbavo85rgyb-0, decimal) '.'
		counter(lst-ctn-kix_ukbavo85rgyb-1, decimal) '.'
		counter(lst-ctn-kix_ukbavo85rgyb-2, decimal) '.'
		counter(lst-ctn-kix_ukbavo85rgyb-3, decimal) '.'
		counter(lst-ctn-kix_ukbavo85rgyb-4, decimal) '.'
		counter(lst-ctn-kix_ukbavo85rgyb-5, decimal) '.'
		counter(lst-ctn-kix_ukbavo85rgyb-6, decimal) '.'
		counter(lst-ctn-kix_ukbavo85rgyb-7, decimal) '.'
		counter(lst-ctn-kix_ukbavo85rgyb-8, decimal) '. ';
}
ol.lst-kix_ru1bpjfhdry7-3.start {
	counter-reset: lst-ctn-kix_ru1bpjfhdry7-3 0;
}
ol.lst-kix_ccpf54yj8ft4-0.start {
	counter-reset: lst-ctn-kix_ccpf54yj8ft4-0 0;
}
ol.lst-kix_hh5nrmz5lj4w-6.start {
	counter-reset: lst-ctn-kix_hh5nrmz5lj4w-6 0;
}
ol.lst-kix_list_1-7.start {
	counter-reset: lst-ctn-kix_list_1-7 0;
}
.lst-kix_list_1-5 > li {
	counter-increment: lst-ctn-kix_list_1-5;
}
ol.lst-kix_ru1bpjfhdry7-0.start {
	counter-reset: lst-ctn-kix_ru1bpjfhdry7-0 0;
}
.lst-kix_b5xso8t77fn6-4 > li {
	counter-increment: lst-ctn-kix_b5xso8t77fn6-4;
}
ol.lst-kix_s4zdcr4jvz42-2.start {
	counter-reset: lst-ctn-kix_s4zdcr4jvz42-2 0;
}
.lst-kix_ru1bpjfhdry7-5 > li:before {
	content: '' counter(lst-ctn-kix_ru1bpjfhdry7-0, decimal) '.'
		counter(lst-ctn-kix_ru1bpjfhdry7-1, decimal) '.'
		counter(lst-ctn-kix_ru1bpjfhdry7-2, decimal) '.'
		counter(lst-ctn-kix_ru1bpjfhdry7-3, decimal) '.'
		counter(lst-ctn-kix_ru1bpjfhdry7-4, decimal) '.'
		counter(lst-ctn-kix_ru1bpjfhdry7-5, decimal) '. ';
}
.lst-kix_ru1bpjfhdry7-1 > li:before {
	content: '' counter(lst-ctn-kix_ru1bpjfhdry7-0, decimal) '.'
		counter(lst-ctn-kix_ru1bpjfhdry7-1, decimal) '. ';
}
.lst-kix_ccpf54yj8ft4-2 > li:before {
	content: '' counter(lst-ctn-kix_ccpf54yj8ft4-0, decimal) '.'
		counter(lst-ctn-kix_ccpf54yj8ft4-1, decimal) '.'
		counter(lst-ctn-kix_ccpf54yj8ft4-2, decimal) '. ';
}
.lst-kix_list_1-2 > li:before {
	content: '' counter(lst-ctn-kix_list_1-2, decimal) ') ';
}
.lst-kix_hh5nrmz5lj4w-1 > li:before {
	content: '' counter(lst-ctn-kix_hh5nrmz5lj4w-1, lower-roman) ') ';
}
.lst-kix_s4zdcr4jvz42-4 > li:before {
	content: '' counter(lst-ctn-kix_s4zdcr4jvz42-4, lower-latin) '. ';
}
.lst-kix_q01hydxackv6-6 > li {
	counter-increment: lst-ctn-kix_q01hydxackv6-6;
}
.lst-kix_hh5nrmz5lj4w-2 > li {
	counter-increment: lst-ctn-kix_hh5nrmz5lj4w-2;
}
.lst-kix_list_1-0 > li {
	counter-increment: lst-ctn-kix_list_1-0;
}
.lst-kix_hh5nrmz5lj4w-5 > li:before {
	content: '(' counter(lst-ctn-kix_hh5nrmz5lj4w-5, decimal) ') ';
}
ol.lst-kix_ru1bpjfhdry7-1.start {
	counter-reset: lst-ctn-kix_ru1bpjfhdry7-1 0;
}
.lst-kix_list_1-6 > li:before {
	content: '' counter(lst-ctn-kix_list_1-6, lower-latin) '. ';
}
.lst-kix_ru1bpjfhdry7-7 > li {
	counter-increment: lst-ctn-kix_ru1bpjfhdry7-7;
}
.lst-kix_ccpf54yj8ft4-5 > li {
	counter-increment: lst-ctn-kix_ccpf54yj8ft4-5;
}
.lst-kix_s4zdcr4jvz42-8 > li:before {
	content: '' counter(lst-ctn-kix_s4zdcr4jvz42-8, lower-roman) '. ';
}
.lst-kix_63mk0cxee5lz-3 > li {
	counter-increment: lst-ctn-kix_63mk0cxee5lz-3;
}
.lst-kix_list_2-2 > li:before {
	content: '' counter(lst-ctn-kix_list_2-0, decimal) '.'
		counter(lst-ctn-kix_list_2-1, decimal) '.'
		counter(lst-ctn-kix_list_2-2, decimal) '. ';
}
ol.lst-kix_hh5nrmz5lj4w-8.start {
	counter-reset: lst-ctn-kix_hh5nrmz5lj4w-8 0;
}
ol {
	margin: 0;
	padding: 0;
}
table td,
table th {
	padding: 0;
}
.c11 {
	border-right-style: solid;
	padding: 5pt 5pt 5pt 5pt;
	border-bottom-color: #000000;
	border-top-width: 1pt;
	border-right-width: 1pt;
	border-left-color: #000000;
	vertical-align: top;
	border-right-color: #000000;
	border-left-width: 1pt;
	border-top-style: solid;
	border-left-style: solid;
	border-bottom-width: 1pt;
	width: 314.2pt;
	border-top-color: #000000;
	border-bottom-style: solid;
}
.c22 {
	border-right-style: solid;
	padding: 5pt 5pt 5pt 5pt;
	border-bottom-color: #000000;
	border-top-width: 1pt;
	border-right-width: 1pt;
	border-left-color: #000000;
	vertical-align: top;
	border-right-color: #000000;
	border-left-width: 1pt;
	border-top-style: solid;
	border-left-style: solid;
	border-bottom-width: 1pt;
	width: 140.2pt;
	border-top-color: #000000;
	border-bottom-style: solid;
}
.c30 {
	border-right-style: solid;
	border-bottom-color: #000000;
	border-top-width: 0pt;
	border-right-width: 0pt;
	border-left-color: #000000;
	vertical-align: top;
	border-right-color: #000000;
	border-left-width: 0pt;
	border-top-style: solid;
	border-left-style: solid;
	border-bottom-width: 0pt;
	width: 415.5pt;
	border-top-color: #000000;
	border-bottom-style: solid;
}
.c46 {
	border-right-style: solid;
	border-bottom-color: #000000;
	border-top-width: 0pt;
	border-right-width: 0pt;
	border-left-color: #000000;
	vertical-align: top;
	border-right-color: #000000;
	border-left-width: 0pt;
	border-top-style: solid;
	border-left-style: solid;
	border-bottom-width: 0pt;
	width: 39pt;
	border-top-color: #000000;
	border-bottom-style: solid;
}
.c3 {
	color: #000000;
	font-weight: 400;
	text-decoration: none;
	vertical-align: baseline;
	font-size: 10pt;
	font-family: 'Open Sans';
	font-style: normal;
}
.c25 {
	color: #000000;
	font-weight: 700;
	text-decoration: none;
	vertical-align: baseline;
	font-size: 12pt;
	font-family: 'Open Sans';
	font-style: normal;
}
.c8 {
	color: #000000;
	font-weight: 400;
	text-decoration: none;
	vertical-align: baseline;
	font-size: 12pt;
	font-family: 'Open Sans';
	font-style: normal;
}
.c23 {
	color: #000000;
	font-weight: 300;
	text-decoration: none;
	vertical-align: baseline;
	font-size: 8pt;
	font-family: 'Open Sans';
	font-style: normal;
}
.c29 {
	color: #000000;
	font-weight: 400;
	text-decoration: none;
	vertical-align: baseline;
	font-size: 7pt;
	font-family: 'Times New Roman';
	font-style: normal;
}
.c27 {
	color: #000000;
	font-weight: 300;
	text-decoration: none;
	vertical-align: baseline;
	font-size: 9pt;
	font-family: 'Open Sans';
	font-style: normal;
}
.c1 {
	padding-top: 0pt;
	padding-bottom: 0pt;
	line-height: 1.5;
	orphans: 2;
	widows: 2;
	text-align: center;
	height: 12pt;
}
.c15 {
	color: #000000;
	font-weight: 400;
	text-decoration: none;
	vertical-align: baseline;
	font-size: 18pt;
	font-family: 'Open Sans';
	font-style: normal;
}
.c36 {
	color: #000000;
	font-weight: 300;
	text-decoration: none;
	vertical-align: baseline;
	font-size: 7pt;
	font-family: 'Open Sans';
	font-style: normal;
}
.c13 {
	padding-top: 0pt;
	padding-bottom: 0pt;
	line-height: 1;
	orphans: 2;
	widows: 2;
	text-align: left;
	margin-right: 18pt;
}
.c24 {
	color: #000000;
	font-weight: 400;
	text-decoration: none;
	vertical-align: baseline;
	font-size: 11pt;
	font-family: 'Trebuchet MS';
	font-style: normal;
}
.c34 {
	padding-top: 0pt;
	padding-bottom: 0pt;
	line-height: 1;
	orphans: 2;
	widows: 2;
	text-align: justify;
}
.c6 {
	padding-top: 3pt;
	padding-bottom: 0pt;
	line-height: 1.5;
	orphans: 2;
	widows: 2;
	text-align: left;
}
.c21 {
	padding-top: 0pt;
	padding-bottom: 0pt;
	line-height: 1.5;
	orphans: 2;
	widows: 2;
	text-align: center;
}
.c48 {
	padding-top: 0pt;
	padding-bottom: 0pt;
	line-height: 1;
	orphans: 2;
	widows: 2;
	text-align: right;
}
.c20 {
	padding-top: 0pt;
	padding-bottom: 0pt;
	line-height: 1;
	orphans: 2;
	widows: 2;
	text-align: left;
}
.c32 {
	padding-top: 10pt;
	padding-bottom: 0pt;
	line-height: 1;
	orphans: 2;
	widows: 2;
	text-align: right;
}
.c12 {
	-webkit-text-decoration-skip: none;
	color: #000000;
	text-decoration: underline;
	vertical-align: baseline;
	text-decoration-skip-ink: none;
	font-style: normal;
}
.c28 {
	padding-top: 10pt;
	padding-bottom: 0pt;
	line-height: 1;
	orphans: 2;
	widows: 2;
	text-align: left;
}
.c18 {
	background-color: #ffff00;
	color: #000000;
	text-decoration: none;
	vertical-align: baseline;
	font-style: normal;
}
.c33 {
	background-color: #ffff00;
	color: #222222;
	text-decoration: none;
	vertical-align: baseline;
	font-style: normal;
}
.c9 {
	padding-top: 0pt;
	padding-bottom: 0pt;
	line-height: 1;
	text-align: left;
	margin-right: -5.2pt;
}
.c4 {
	font-size: 10pt;
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 400;
}
.c44 {
	margin-left: -5pt;
	border-spacing: 0;
	border-collapse: collapse;
	margin-right: auto;
}
.c2 {
	border-spacing: 0;
	border-collapse: collapse;
	margin-right: auto;
}
.c40 {
	color: #000000;
	text-decoration: none;
	vertical-align: baseline;
	font-style: normal;
}
.c47 {
	color: #222222;
	text-decoration: none;
	vertical-align: baseline;
	font-style: normal;
}
.c35 {
	font-size: 9pt;
	font-family: 'Open Sans';
	font-weight: 300;
}
.c39 {
	font-size: 18pt;
	font-family: 'Open Sans';
	font-weight: 600;
}
.c14 {
	-webkit-text-decoration-skip: none;
	text-decoration: underline;
	text-decoration-skip-ink: none;
}
.c26 {
	font-size: 9pt;
	font-family: 'Open Sans';
	font-weight: 700;
}
.c16 {
	font-size: 9pt;
	font-family: 'Open Sans';
	font-weight: 400;
}
.c43 {
	background-color: #ffffff;
	max-width: 454pt;
	padding: 127.4pt 69pt 70.9pt 72pt;
}
.c0 {
	font-size: 10pt;
	font-family: 'Open Sans';
	font-weight: 700;
}
.c5 {
	font-size: 10pt;
	font-family: 'Open Sans';
	font-weight: 400;
}
.c45 {
	font-weight: 400;
	font-size: 10pt;
	font-family: 'Times New Roman';
}
.c31 {
	color: inherit;
	text-decoration: inherit;
}
.c37 {
	margin-right: 48pt;
}
.c19 {
	margin-left: 46.4pt;
}
.c17 {
	height: 0pt;
}
.c7 {
	margin-left: 28.4pt;
}
.c41 {
	color: #1155cc;
}
.c42 {
	margin-left: 26pt;
}
.c38 {
	height: 18pt;
}
.c10 {
	height: 12pt;
}
.title {
	padding-top: 24pt;
	color: #000000;
	font-weight: 700;
	font-size: 36pt;
	padding-bottom: 6pt;
	font-family: 'Times New Roman';
	line-height: 1;
	page-break-after: avoid;
	orphans: 2;
	widows: 2;
	text-align: left;
}
.subtitle {
	padding-top: 18pt;
	color: #666666;
	font-size: 24pt;
	padding-bottom: 4pt;
	font-family: 'Georgia';
	line-height: 1;
	page-break-after: avoid;
	font-style: italic;
	orphans: 2;
	widows: 2;
	text-align: left;
}
li {
	color: #000000;
	font-size: 12pt;
	font-family: 'Times New Roman';
}
p {
	margin: 0;
	color: #000000;
	font-size: 12pt;
	font-family: 'Times New Roman';
}
h1 {
	padding-top: 24pt;
	color: #000000;
	font-weight: 700;
	font-size: 24pt;
	padding-bottom: 6pt;
	font-family: 'Times New Roman';
	line-height: 1;
	page-break-after: avoid;
	orphans: 2;
	widows: 2;
	text-align: left;
}
h2 {
	padding-top: 18pt;
	color: #000000;
	font-weight: 700;
	font-size: 18pt;
	padding-bottom: 4pt;
	font-family: 'Times New Roman';
	line-height: 1;
	page-break-after: avoid;
	orphans: 2;
	widows: 2;
	text-align: left;
}
h3 {
	padding-top: 14pt;
	color: #000000;
	font-weight: 700;
	font-size: 14pt;
	padding-bottom: 4pt;
	font-family: 'Times New Roman';
	line-height: 1;
	page-break-after: avoid;
	orphans: 2;
	widows: 2;
	text-align: left;
}
h4 {
	padding-top: 12pt;
	color: #000000;
	font-weight: 700;
	font-size: 12pt;
	padding-bottom: 2pt;
	font-family: 'Times New Roman';
	line-height: 1;
	page-break-after: avoid;
	orphans: 2;
	widows: 2;
	text-align: left;
}
h5 {
	padding-top: 11pt;
	color: #000000;
	font-weight: 700;
	font-size: 11pt;
	padding-bottom: 2pt;
	font-family: 'Times New Roman';
	line-height: 1;
	page-break-after: avoid;
	orphans: 2;
	widows: 2;
	text-align: left;
}
h6 {
	padding-top: 10pt;
	color: #000000;
	font-weight: 700;
	font-size: 10pt;
	padding-bottom: 2pt;
	font-family: 'Times New Roman';
	line-height: 1;
	page-break-after: avoid;
	orphans: 2;
	widows: 2;
}
