body {
	margin: 0;
	font-family: 'Open Sans', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.mdl-shadow--2dp {
	box-shadow: none;
}

.firebaseui-info-bar {
	margin-top: 20px;
}

div.mdl-progress::after {
	display: block;
	color: black;
	content: 'Authenticating';
	margin: 20px auto;
	text-align: center;
}

.mdl-progress {
	height: 5px;
}

#root,
html,
body {
	height: 100%;
	margin: 0;
}
* {
	box-sizing: border-box;
}

#root {
	display: flex;
	flex-direction: column;
}
@keyframes wawe {
	0% {
		transform: translateX(-100%);
	}
	60% {
		transform: translateX(100%);
	}
	100% {
		transform: translateX(100%);
	}
}

/* Scroll-bar */
::-webkit-scrollbar {
	width: 5px;
}

::-webkit-scrollbar-track {
	background: #f6f7f7;
	border-radius: 10px;
}

::-webkit-scrollbar-thumb {
	background: #cccfce;
	border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
	background: #989f9d;
}
